import '@/helpers/domains'
import '@/plugins/echarts'

import App from '@/App.vue'
import { RouterAdmin } from '@/RouterAdmin'
import { StoreAdmin } from '@/StoreAdmin'
import AdminAccessControlRules from '@/accessControl/rules'
import SentryHelper from '@/helpers/SentryHelper'
import { i18n } from '@/i18n/setup'
import { initPubSub } from '@/pubsub/subscriber'
import { abilitiesPlugin } from '@casl/vue'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueModal from 'vue-js-modal'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import VueTippy from 'vue-tippy'
import VueToasted from 'vue-toasted'
import Vuex from 'vuex'

initPubSub()

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueMeta)
Vue.use(PortalVue) // TODO: с vue3 заменить на встроенный teleport
Vue.use(VueModal)

Vue.use(VueTippy, {
  onShow(options) {
    return Boolean(options.props.content)
  },
})

Vue.use(VueToasted, {
  position: 'top-right',
  duration: 2000,
})

Vue.use(VCalendar)
Vue.use(abilitiesPlugin, AdminAccessControlRules.ability)

const createApp = async function () {
  const router = RouterAdmin.init()
  const store = StoreAdmin.init()

  SentryHelper.init(router)

  return new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  })
}

/**
 * Обработка ошибки отсутвующего чанка
 * https://vitejs.dev/guide/build.html#load-error-handling
 */
const processViteChunkError = () => {
  window.addEventListener('vite:preloadError', (event) => {
    // eslint-disable-next-line no-console
    console.log('processViteChunkError', event)

    window.location.reload()
  })
}

if (process.iblsEnv.IS_DEVELOPMENT) {
  console.log(process.iblsEnv)
}

createApp().then((app) => {
  app.$mount('#app')

  processViteChunkError()
})
