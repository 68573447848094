<template>
  <div id="app">
    <transition-group name="fade" mode="out-in">
      <template v-if="!isLoading">
        <Theme key="theme" />

        <router-view v-if="isRendered.page" key="layout" />
      </template>
    </transition-group>
  </div>
</template>

<script>
import Theme from '@/components/Theme.vue'
import { initTrackers } from '@/helpers/trackers/init'
import FontFaceObserver from 'fontfaceobserver'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',

  components: {
    Theme,
  },

  metaInfo: {
    titleTemplate(titleChunk) {
      return titleChunk ? `${titleChunk} | IBLS` : 'IBLS'
    },
  },

  data() {
    return {
      isLoadingProfile: false,
      isLoadingFonts: false,
    }
  },

  computed: {
    ...mapState({
      isLoadingTheme: (state) => state.admin.theme.isLoading,
    }),

    ...mapState('admin/navigation', [
      'textsLoaded',
    ]),

    isLoading() {
      return this.isLoadingTheme || this.isLoadingFonts || this.isLoadingProfile
    },

    isTextsLoaded() {
      return this.textsLoaded
    },

    isAuthRequired() {
      return (
        this.$route.meta.requiresAuth === true ||
        typeof this.$route.meta.requiresAuth === 'undefined'
      )
    },

    isRendered() {
      return {
        page: !this.isAuthRequired || this.isTextsLoaded,
      }
    },
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions('admin/theme', [
      'getTheme',
    ]),

    ...mapActions('admin/navigation', [
      'fetchProfile',
    ]),

    async init() {
      this.isLoadingProfile = true

      try {
        await this.initFonts()

        if (this.isAuthRequired) {
          await Promise.all([
            this.getTheme(),
            this.fetchProfile(),
            initTrackers(),
          ])
        }
      } finally {
        this.isLoadingProfile = false
      }
    },

    async initFonts() {
      this.isLoadingFonts = true

      await document.fonts.ready

      const fontawesome = new FontFaceObserver('Font Awesome 5 Free')

      await fontawesome.load()

      this.isLoadingFonts = false
    },
  },
}
</script>

<style lang="scss">
@import '~@/app';
</style>
